import { MEASURE_FORMULAS } from '~/constants/formulas'
import { MEASURE_TITLES, DIMENSION_TITLES } from '~/constants/analytics'
import type { ModuleInfoContent } from '~/types/analytics'
import { SourceType } from '~/types/sources'

export const MEASURE_DEFINITION_BY_TITLE: {
  [key in MEASURE_TITLES]?: ModuleInfoContent
} = {
  [MEASURE_TITLES.EMPLOYEE_COUNT_END]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_ARRIVAL]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_ARRIVAL}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_ARRIVAL}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE}.info.definition`,
    boldDefinition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE}.info.boldDefinition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_NET_ARRIVAL]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_NET_ARRIVAL}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_NET_ARRIVAL}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE_FINANCE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE_FINANCE}.info.definition`,
    boldDefinition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE_FINANCE}.info.boldDefinition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_DEPARTURE_FINANCE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_FTE_HR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FTE_HR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FTE_HR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_FTE_HR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_FTE_HR}.info.definition`,
    source: SourceType.HRIS
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_TENURE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TENURE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TENURE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_GROWTH_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_GROWTH_RATE}.info.definition`,
    calculation: {
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_GROWTH_RATE],
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_GROWTH_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_GROWTH_RATE}.info.calculation.legend2`
      ]
    },
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_GROWTH_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE],
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE}.info.calculation.legend2`
      ]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_GENDER_BASE_SALARY_GAP_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE_AT_MONTH_END]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE_AT_MONTH_END}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_AVERAGE_AT_MONTH_END}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_PERMANENT_WORKFORCE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_PERMANENT_WORKFORCE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_PERMANENT_WORKFORCE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_FISCAL_PERMANENT_WORKFORCE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FISCAL_PERMANENT_WORKFORCE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FISCAL_PERMANENT_WORKFORCE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_MANAGER]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_MANAGER}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_MANAGER}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD_MANAGER]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD_MANAGER}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_OVER_PERIOD_MANAGER}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER_OVER_PERIOD]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER_OVER_PERIOD}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_MANAGER_OVER_PERIOD}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_SPAN_OF_CONTROL]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SPAN_OF_CONTROL}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SPAN_OF_CONTROL}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_MAX_SPAN_OF_CONTROL]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_MAX_SPAN_OF_CONTROL}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_MAX_SPAN_OF_CONTROL}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED}.info.definition`,
    calculation: {
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED],
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED}.info.calculation.legend2`
      ]
    },
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_DEPARTURE_REGRETTED}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_STAY]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_STAY}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_STAY}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ATTRITION]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION}.info.calculation.legend2`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION}.info.calculation.legend3`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ATTRITION]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED}.info.calculation.legend2`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED}.info.calculation.legend3`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_REGRETTED}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED}.info.calculation.legend2`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED}.info.calculation.legend3`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ATTRITION_NOT_REGRETTED}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_TURNOVER]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_TURNOVER}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_TURNOVER}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_TURNOVER}.info.calculation.legend2`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_TURNOVER}.info.calculation.legend3`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_TURNOVER]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_TURNOVER}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RETENTION]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RETENTION}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RETENTION}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RETENTION}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_RETENTION]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RETENTION}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[
          MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE
        ]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_TRIAL_PERIOD_NON_TRANSFORMATION_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_YEAR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_YEAR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_YEAR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_SIX_MONTHS]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_SIX_MONTHS}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_END_FIRST_SIX_MONTHS}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_YEAR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_YEAR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_YEAR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_SIX_MONTHS]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_SIX_MONTHS}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_FIRST_SIX_MONTHS}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[
          MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE
        ]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_YEAR_NON_TRANSFORMATION_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[
          MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE
        ]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_FIRST_SIX_MONTHS_NON_TRANSFORMATION_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_UNPLANNED_SHORT_ABSENCE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_SUM_WORKING_DAYS]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_WORKING_DAYS}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_WORKING_DAYS}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_RATIO_EMPLOYEE_ABSENT}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENCE_FREQUENCY}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[
          MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION
        ]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_ABSENCE_UNPLANNED_DURATION}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE}.info.definition`,
    source: SourceType.HRIS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_ABSENTEEISM_COST_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_SUM_PAYROLL_TOTAL_GROSS_EUR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_PAYROLL_TOTAL_GROSS_EUR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_PAYROLL_TOTAL_GROSS_EUR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_COUNT_CURRENCY]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_CURRENCY}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_COUNT_CURRENCY}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_TO_SALARY_BASE_RAISE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_TO_SALARY_BASE_RAISE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_TIME_TO_SALARY_BASE_RAISE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_BASE_RAISE_RATE}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_MEDIAN_SALARY_TOTAL_EUR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_SUM_SALARY_TOTAL_EUR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_SALARY_TOTAL_EUR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_SUM_SALARY_TOTAL_EUR}.info.insight`
  },
  [MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_TOTAL_EUR]: {
    definition: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_TOTAL_EUR}.info.definition`,
    source: SourceType.HRIS,
    insight: `cube.measure.${MEASURE_TITLES.EMPLOYEE_AVERAGE_SALARY_TOTAL_EUR}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_HIRE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_HIRE}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_HIRE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE}.info.definition`,
    source: SourceType.ATS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_ACCEPTANCE_RATE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE}.info.definition`,
    source: SourceType.ATS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_ACCEPTANCE_RATE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE}.info.definition`,
    source: SourceType.ATS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE}.info.calculation.legend2`
      ],
      formula:
        MEASURE_FORMULAS[MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_GLOBAL_REJECTION_RATE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE}.info.definition`,
    source: SourceType.ATS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_WITHDRAWAL_RATE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE}.info.definition`,
    source: SourceType.ATS,
    calculation: {
      legends: [
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE}.info.calculation.legend1`,
        `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE}.info.calculation.legend2`
      ],
      formula: MEASURE_FORMULAS[MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE]
    },
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_OFFER_REJECTION_RATE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_END_JOB_OPEN]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_END_JOB_OPEN}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_END_JOB_OPEN}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_OFFER]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_OFFER}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_OFFER}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_APPLY]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_APPLY}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_APPLY}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_NOT_CONTACTED_APPLY_RATIO]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_NOT_CONTACTED_APPLY_RATIO}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_NOT_CONTACTED_APPLY_RATIO}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FIRST_FILL]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FIRST_FILL}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FIRST_FILL}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FILL_AND_ARCHIVE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FILL_AND_ARCHIVE}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_FILL_AND_ARCHIVE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_OFFER]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_OFFER}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_OFFER}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_HIRE]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_HIRE}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_HIRE}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_HIRES_BY_RECRUITER]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_HIRES_BY_RECRUITER}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_HIRES_BY_RECRUITER}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_BEGIN_INTERVIEW]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_BEGIN_INTERVIEW}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_BEGIN_INTERVIEW}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_INTERVIEW]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_INTERVIEW}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_AVERAGE_TIME_TO_INTERVIEW}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_OFFER]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_OFFER}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_OFFER}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_INTERVIEWED_CANDIDATES]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_INTERVIEWED_CANDIDATES}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_INTERVIEWED_CANDIDATES}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_END_APPLY_IN_PROGRESS]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_END_APPLY_IN_PROGRESS}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_END_APPLY_IN_PROGRESS}.info.insight`
  },
  [MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_APPLY]: {
    definition: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_APPLY}.info.definition`,
    source: SourceType.ATS,
    insight: `cube.measure.${MEASURE_TITLES.RECRUITMENT_COUNT_REJECTED_APPLY}.info.insight`
  }
}

export const DIMENSION_DEFINITION_BY_TITLE: {
  [key in DIMENSION_TITLES]?: ModuleInfoContent
} = {
  [DIMENSION_TITLES.EMPLOYEE_SUBORGANIZATION]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_SUBORGANIZATION}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_DEPARTMENT]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_DEPARTMENT}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_LOCATION_OFFICE]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_LOCATION_OFFICE}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_LOCATION_REMOTE]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_LOCATION_REMOTE}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_CAREER_LEVEL}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_CAREER_SUB_LEVEL]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_CAREER_SUB_LEVEL}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_GROUP_1]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_GROUP_1}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_GROUP_2]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_GROUP_2}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_GROUP_3]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_GROUP_3}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_LOCATION_1]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_LOCATION_1}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.EMPLOYEE_LOCATION_2]: {
    definition: `cube.dimension.${DIMENSION_TITLES.EMPLOYEE_LOCATION_2}.info.definition`,
    source: SourceType.HRIS
  },
  [DIMENSION_TITLES.JOB_SUBORGANIZATION]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_SUBORGANIZATION}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_DEPARTMENT]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_DEPARTMENT}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_GROUP_1]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_GROUP_1}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_GROUP_2]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_GROUP_2}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_GROUP_3]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_GROUP_3}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_LOCATION_1]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_LOCATION_1}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_OFFER]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_OFFER}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_HIRE]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_HIRE}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_FIRST_FILL]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_FIRST_FILL}.info.definition`,
    source: SourceType.ATS
  },
  [DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_FILL_AND_ARCHIVE]: {
    definition: `cube.dimension.${DIMENSION_TITLES.JOB_APPLICATION_TIME_TO_FILL_AND_ARCHIVE}.info.definition`,
    source: SourceType.ATS
  }
}
